@use "../base/mixins";
@import "../base/variables";

.page {
    min-height: 100vh;
    text-align: center;
    color: $color1;
    
    @include mixins.testMixin;
    @include mixins.sizeTest{
      background-color: aquamarine;
    }
  
  }