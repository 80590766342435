@use "../base/mixins";

.wrapper{
    margin-right: auto;
    margin-left: auto;

    &__content{
        max-width: 80%;
        margin-top: 7em;
        margin-bottom: 5em;
        border-radius: 5px;
        padding: 1em;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: white;

        /* &--is-visible{
            opacity: 1;
            display: block;
        }
        */

        @include mixins.atSmall{
            margin-top: 6em;
            max-width: 70%;
        }
        
        @include mixins.atMedium{
            padding-left: 2em;
            padding-right: 2em;
            margin-top: 6em;
            max-width: 60%;
        }
        
        @include mixins.atLarge{
            /* color: red; */
            max-width: 50%;
        }

        &-text{
            margin-right: auto;
            margin-left: auto;
            
            @include mixins.atSmall{
                max-width: 90%;
            }

            @include mixins.atMedium{
                max-width: 90%;
            }
            
            @include mixins.atLarge{
                max-width: 550px;
            }

            p{
                margin: 13px 0;
            }

            ul{
                margin-top: 1em;
                padding-left: 1em;
            }

        }

        &-spinner{
            display: block;
            color: red;
            align-content: center;
        }
    }
    
}


// En liten snutt för att centrera "spinner"
.spinner span{
    margin: 0 auto;
}

