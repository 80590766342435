@import "../base/variables";


.headline{
    font-weight: bold;
}

.citat{
    margin-right: auto;
    margin-left: auto;
    font-style: italic;
    font-weight: 600;
    max-width: 80%;
    margin-bottom: 1em;
    padding-bottom: 1em;
    color: $citatTextColor;

    /* border-bottom: 2px solid black; */
    

    &__kommentar{
        font-weight: normal;
        font-size: smaller;
    }
}

.note{
    border-top: black solid 2px;
    border-bottom: black solid 2px;
    padding: 1em;
    font-style: italic;
}