@use "../base/mixins";
@use "../base/variables";

table, td, th{
    
    border: 2px solid $tableColor1;
    border-collapse: collapse;
    /* padding-bottom: 5px; */
    /* width: 100%; */
    table-layout: fixed;

    
}
table{
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    margin-bottom: 2em;

    @include mixins.atLarge{

        max-width: 550px;
    }

    
}
th{
    padding-bottom: .5em;
    padding-top: .5em;
    /* color: white; */
}
caption{
    font-size: small;
    caption-side: bottom;
    margin-top: .5em;
}
.postTitle{
    font-weight: bold;
}
thead{
    background-color:$tableColor1;
    /* background-color: mediumaquamarine; */
    
    text-align: center;
}
td{
    font-size: small;
    table-layout: auto;
    text-align: left;
    vertical-align: middle;
    padding: 0.4em;

    @include mixins.atMedium{
        font-size: initial;
    }
       
}

.postBottom{
    border-bottom: solid 3px $tableColor1;
    
}