@mixin testMixin{
    font-size: larger;
    background-color: antiquewhite;
}

@mixin sizeTest{
    @media(min-width: 700px){
        @content;
    }
}

@mixin atSmall {
    @media(min-width: 530px){
        @content;
    } 
}

@mixin atMedium{
    @media(min-width:800px){
        @content;
    }
}

@mixin atLarge{
    @media(min-width:1010px){
        @content;
    }
}

@mixin atWide{
    @media(min-width: 1600px){
        @content;
    }
}

@mixin clearFix{
    &::after{
        content: "";
        clear: both;
        display: table;
    }
}