@use "../base/mixins";

.site-header{
    padding: 10px 0;
    position: absolute;
    width: 100%;
    z-index: 2;
    background-color: black;
    /* background-color: gray; */
    color: white;
    top: 0;
    height: 50px;
    
    position: fixed;
    

    &__logo{
        position: absolute;
        padding: 15px 25px;
        
        @include mixins.atSmall{
            padding: 15px 25px;
        }
    
        @include mixins.atLarge{
            padding-left: 7%;
            /* padding-left: 50px; */
        }
    
        @include mixins.atWide{
            padding-left: 23%;
            /* padding-left: 350px; */
        }
    }


    &__menu-icon{
        width: 20px;
        height: 19px;
        position: absolute;
        top: 25px;
        right: 40px;
        cursor: pointer;


        &::before{
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 3px;
            background-color: white;
            transform-origin: 0 0;
            transition: transform .1s ease-out;
        }
        &__middle{
            position: absolute;
            top: 8px;
            left: 0;
            width: 20px;
            height: 3px;
            background-color: white;
            transform-origin: 0 50%;
            transition: all .1s ease-out;
        }
        &::after{
            content:"";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 20px;
            height: 3px;
            background-color: white;
            transform-origin: 0 100%;
            transition: transform .1s ease-out;
        }

        @include mixins.atLarge{
            display: none;
        }
    }

    &__menu-icon--close-x{
        &::before{
            transform:rotate(45deg) scaleX(1.25);
        }
        .site-header__menu-icon__middle{
            opacity: 0;
            transform: scaleX(0);
        }
        &::after{
            transform:rotate(-45deg) scaleX(1.25) translateY(1px);
        }
    }

    &__menu-content{
        display: none;
        padding: 115px 0;
        
        /* transition: display 0.5s ease-out; */
        @include mixins.atSmall{
            padding: 115px 0;
        }

        @include mixins.atLarge{
            
            display: block;
            padding: 65px 0;
        }
        &--is-visible{
            display:block;
        }
    }


}

.primary-nav{
    
    position: relative;
    top: -55px;
    

    &--pull-right{
        float: right;
    }

    @include mixins.atLarge{
        padding-right: 7%;
        /* padding-right: 50px; */
    }
    @include mixins.atWide{
        padding-right: 23%;
        /* padding-right: 350px; */
    }

    ul{
        margin: 0;
        padding: 0;
        text-align: center;
        @include mixins.clearFix;
        
    }

    li{
        background-color:rgba(243, 243, 243, 0.90);
        border: outset 3px rgba(247, 247, 247, 0.5);
        list-style: none;
        display: block;
        margin-right: -4px;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 0.3em;
        padding-bottom: 0.3em;

        transition: all 0.3s ease-out;

        @include mixins.atLarge{
            /* margin-right: 10px; */
            background-color:black;
            border: none;    
            float: left;
            padding-right: 20px;
            padding-left: 0px;
            padding-top: 0.2em;
            padding-bottom: 0.2em;

        }
        
    }
    .is-active{
        font-weight: 600;
        text-decoration: none !important;
        color: rgb(172, 171, 171);

        @include mixins.atSmall{
            color: rgb(135,135,135);
            font-weight: bold;
        }
        
    }
    
    li:last-child{

        padding-right: 20px;

        @include mixins.atLarge{
            padding-right: 0px;
        }
        @include mixins.atWide{
            padding-right: 0px;
        }
    }
    

    a{
        color: black;
        text-decoration: none;
        font-weight: 600;
        
        
        /* &:hover{
            
            font-size: initial;
            color: transparent;
            text-shadow: 0 0 1.5px black;
            
            
            
        }
        */
        
        @include mixins.atLarge{
            color: white;
            font-weight: 300;

            &:hover{
                color: rgba(255, 255, 255, 0.5);
                
                /* font-size: initial; */
                /* color: white; */
                /* color: transparent; */
                text-shadow: 0 0 5px white;
                /* text-shadow: 0 0 5px LightYellow; */
                
                
                /* text-decoration: underline; */
            }

        }
    }
}