@use "../base/mixins";

.front-pic{
    position: relative;
    
    margin-bottom: 0.5em;
    
    /* top: 5px; */
    
    @include mixins.atLarge{
        left: -20px;
    }

    & > img{
        
        width: 60px;
        height: 60px;
        /* border: solid 5px white; */
        border-radius: 50%;
        overflow: hidden;

        @include mixins.atSmall{
            width: 100px;
            height: 100px;
        }
    }
}